<template>
  <b-row>
    <b-col
      md="6"
      sm="12"
    >
      <b-card title="Информация">
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >

            <!-- ФИО -->
            <b-form-group
              label="ФИО"
              label-for="full-name"
            >
              <b-form-input
                id="full-name"
                v-model="userData.name"
                autofocus
                trim
                placeholder=""
                autocomplete="off"
                readonly
              />
            </b-form-group>

            <!-- Номер телефон -->
            <b-form-group
              label="Номер телефон"
              label-for="phone"
            >
              <b-form-input
                id="phone"
                v-model="userData.phone"
                autofocus
                trim
                placeholder=""
                autocomplete="off"
                readonly
              />
            </b-form-group>

            <!-- Имя пользователя -->
            <b-form-group
              label="Имя пользователя"
              label-for="username"
            >
              <b-form-input
                id="username"
                v-model="userData.username"
                trim
                readonly
              />
            </b-form-group>

            <!-- Эл. почта -->
            <b-form-group
              label="Эл. почта"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="userData.email"
                trim
                readonly
              />
            </b-form-group>

            <!-- Организация -->
            <b-form-group
              label="Организация"
              label-for="organization"
            >
              <b-form-input
                id="organization"
                v-model="userData.organization"
                trim
                readonly
              />
            </b-form-group>

            <b-media class="mb-2">
              <template #aside>
                <b-avatar
                  ref="previewEl"
                  :src="userData.avatar"
                  :text="avatarText(userData.name)"
                  :variant="`light-primary`"
                  size="90px"
                  rounded
                  @click="$refs.refInputEl.click()"
                />
              </template>
              <div class="d-flex flex-wrap">
                <b-button
                  variant="primary"
                  @click="$refs.refInputEl.click()"
                >
                  <input
                    ref="refInputEl"
                    type="file"
                    class="d-none"
                    accept="image/*"
                    @input="inputImageRenderer"
                  >
                  <feather-icon
                    icon="DownloadIcon"
                    class="d-inline"
                  />
                </b-button>
              </div>
            </b-media>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                Сохранить
              </b-button>
            </div>

          </b-form>
        </validation-observer>

      </b-card>
    </b-col>
    <b-col
      md="6"
      sm="12"
    >
      <b-card title="Безопасность">
        <!-- BODY -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refPasswordFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onPasswordSubmit)"
          >
            <!-- Old Password -->
            <validation-provider
              #default="{ errors }"
              name="старый пароль"
              vid="old-password"
              rules="required|min:8"
            >
              <b-form-group
                label-for="register-password"
                label="Старый пароль"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="old-password"
                    v-model="userPasswordData.old_password"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false:null"
                    name="old-password"
                    placeholder="············"
                    autocomplete="off"
                    trim
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Password -->
            <validation-provider
              #default="{ errors }"
              name="новый пароль"
              vid="password"
              rules="required|min:8"
            >
              <b-form-group
                label-for="register-password"
                label="Новый пароль"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="userPasswordData.password"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false:null"
                    name="password"
                    placeholder="············"
                    autocomplete="off"
                    trim
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Подтверждение пароль -->
            <validation-provider
              #default="{ errors }"
              name="подтверждение новый пароль"
              vid="Подтверждение новый пароль"
              rules="required|confirmed:password"
            >
              <b-form-group
                label-for="confirm-password"
                label="Подтверждение новый пароль"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="confirm-password"
                    v-model="userPasswordData.password_confirmation"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false:null"
                    autocomplete="off"
                    placeholder="············"
                    trim
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                Сохранить
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="resetPasswordForm"
              >
                Отменить
              </b-button>
            </div>

          </b-form>
        </validation-observer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BMedia,
  BAvatar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import ru from 'vee-validate/dist/locale/ru.json'
import { ref } from '@vue/composition-api'
import {
  required, alphaNum, email, regex,
} from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import http from '@axios'
import { avatarText } from '@core/utils/filter'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { useToast } from 'vue-toastification/composition'

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BMedia,
    BAvatar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      required,
      alphaNum,
      email,
      regex,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  created() {
    this.getUser()
  },
  methods: {
    getUser() {
      this.$http
        .get('https://edu.startupchoikhona.tj/backend/api/profile', {})
        .then(response => {
          const {
            data,
          } = response.data
          this.userData = {
            name: data.name,
            phone: data.phone,
            username: data.username,
            email: data.email,
            organization: data.organization,
            avatar: data.photo ? `https://edu.startupchoikhona.tj/backend/${data.photo}` : '',
            photo: null,
          }
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ошибка при получение менторы',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

  },
  setup(props, { emit }) {
    const toast = useToast()
    const blankUserData = {
      name: '',
      phone: '',
      username: '',
      email: '',
      organization: '',
      avatar: null,
      photo: null,
    }
    const blankUserPasswordData = {
      old_password: '',
      password: '',
      password_confirmation: '',
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const userPasswordData = ref(JSON.parse(JSON.stringify(blankUserPasswordData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }
    const resetuserpasswordData = () => {
      userPasswordData.value = JSON.parse(JSON.stringify(blankUserPasswordData))
    }

    const buildFormData = (formData, data, parentKey) => {
      if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data)
          .forEach(key => {
            if (data[key] && key !== 'avatar') {
              buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key)
            }
          })
      } else {
        const value = data == null ? '' : data

        formData.append(parentKey, value)
      }
    }
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      userData.value.avatar = base64
      userData.value.photo = refInputEl.value.files[0]
    })

    const onSubmit = () => {
      const formData = new FormData()

      buildFormData(formData, userData.value)
      http.post('https://edu.startupchoikhona.tj/backend/api/profile/photo/update', formData)
        .then(response => {
          const userInfo = JSON.parse(localStorage.getItem('userData'))
          userInfo.photo = response.data.data.photo
          localStorage.setItem('userData', JSON.stringify(userInfo))
          toast({
            component: ToastificationContent,
            props: {
              title: 'Успешно',
              icon: 'CheckIcon',
              variant: 'success',
              text: response.data.message,
            },
          })
          window.location.reload()
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Ошибка',
              icon: 'XIcon',
              variant: 'success',
              text: error.response.data.message,
            },
          })
        })
    }

    const onPasswordSubmit = () => {
      const formData = new FormData()

      buildFormData(formData, userPasswordData.value)
      http.post('https://edu.startupchoikhona.tj/backend/api/profile/password/update', formData)
        .then(response => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Успешно',
              icon: 'CheckIcon',
              variant: 'success',
              text: response.data.message,
            },
          })
          resetPasswordForm()
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Ошибка',
              icon: 'XIcon',
              variant: 'success',
              text: error.response.data.message,
            },
          })
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)
    const passwordFormV = formValidation(resetuserpasswordData)
    const refPasswordFormObserver = passwordFormV.refFormObserver
    const getPasswordValidationState = passwordFormV.getValidationState
    const resetPasswordForm = passwordFormV.resetForm
    localize('ru', ru)
    return {
      avatarText,
      refInputEl,
      previewEl,
      userData,
      userPasswordData,
      onSubmit,
      onPasswordSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
      refPasswordFormObserver,
      getPasswordValidationState,
      resetPasswordForm,
      inputImageRenderer,
    }
  },
}
</script>

<style>

</style>
